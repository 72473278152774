<template>
  <div>
    <b-card-group deck>
      <notifications group="alert" position="top center" />
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Danh sách bảo trì bank</h6>
        </template>

        <b-row>
          <b-col>
            <b-form-group label="Tiêu đề">
              <b-form-input v-model="filter.note"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Loại liên kết">
              <b-form-select v-model="filter.link_type" :options="linkType"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Ngân hàng">
              <b-form-input v-model="filter.bank_code"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Từ ngày (Ngày tạo)">
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="filter.date_from"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Đến ngày (Ngày tạo)">
              <b-form-input
                  :id="`type-date`"
                  :type="`date`"
                  v-model="filter.date_to"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="actions text-right">
              <b-button class="mr-2" variant="outline-info" @click="listBankMaintenance(1)">Tìm kiếm</b-button>
              <a class="mr-2 btn btn-primary" href="#/tools/bank/maintenance/create">Thêm mới</a>
            </div>
          </b-col>
        </b-row>
        <hr>

        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(index)="data">
            {{(currentPage - 1) * 25 + (data.index + 1)}}
          </template>
          <template #cell(banks)="data">
            <ul v-if="data.item.banks" class="list-inline">
              <li v-for="item in data.item.banks" :key="item.id" class="list-inline-item">
                <span class="badge badge-success">{{ item.bank_code }}</span>
              </li>
            </ul>
          </template>
          <template #cell(action)="data">
            <b-button-group class="btn-group-sm">
              <b-button variant="success" @click="update(data.item.id)">Sửa</b-button>
              <b-button variant="danger" @click="remove(data.item.id)">Xóa</b-button>
            </b-button-group>
          </template>

        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>
<style src="../../../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.marginLeft {
  margin-left: 30px;
}
</style>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Notifications from "vue-notification";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const CmsRepository = RepositoryFactory.get("cms");
Vue.component("multiselect", Multiselect);
Vue.use(Notifications);

export default {
  components: {
  },
  mixins: [Common],
  data() {
    return {
      filter: {
        note: null,
        link_type: null,
        date_from: null,
        date_to: null,
        bank_code: null
      },
      items: [],
      fields: [
        { index: "STT" },
        { title: "Tiêu đề" },
        { type: "Loại liên kết" },
        { banks: "Ngân hàng" },
        { start_time: "Thời gian bắt đầu" },
        { end_time: "Thời gian kết thúc" },
        { created_by: "Người tạo" },
        { created_at: "Ngày tạo" },
        { updated_at: "Ngày cập nhật" },
        { action: "Hành động" },
      ],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      linkType: [
        { text: "Tất cả", value: "ALL" },
        { text: "LK trực tiếp", value: "DIRECT_LINK" },
        { text: "LK Napas", value: "NAPAS_PAYGATE" },
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Danh sách bảo trì bank", route: "tools/bank/maintenance" },
    ]);
  },
  methods: {
    listBankMaintenance(page = 1) {
      this.items = [];
      let params = { ...this.filter };
      params = this.convert(params);
      params.page = page;
      this.$bus.$emit("show-loading", true);
      CmsRepository.listMaintenance(params)
      .then((response) => {
        if (response.data.error_code) {
          this.notifyAlert("warn", response.data.message);
          return;
        }

        this.items = response.data.data.data;
        this.paginate.total = response.data.data.total;
        this.paginate.totalPage = response.data.data.last_page;
        this.paginate.currentPage = response.data.data.current_page;
        this.$bus.$emit("show-loading", false);
      })
      .catch(() => {
        this.$bus.$emit("show-loading", false);
        alert("Có lỗi xảy ra");
      });
    },
    update(id) {
      return this.$router.push({ name: "updateMaintenance", query: { id: id } });
    },
    remove(id) {
      let cfm = confirm("Bạn có chắc muốn xóa mục này?");

      if (!cfm) {
        return true;
      }

      this.$bus.$emit("show-loading", true);
      CmsRepository.removeBankMaintenance(id)
        .then((response) => {
          if (response.data.error_code) {
            this.notifyAlert("warn", response.data.message);
            return;
          }

          window.location.reload();
          this.$bus.$emit("show-loading", false);
        })
        .catch(() => {
          this.$bus.$emit("show-loading", false);
          alert("Có lỗi xảy ra");
        });
    },
    convert(params) {
      for (let key in params) {
        if (params[key] == "") {
          delete params[key];
        }
      }
      return params;
    },
  },
  watch: {
    currentPage() {
      // this.listAll();
    },
  },
  created() {
    this.listBankMaintenance();
  },
};
</script>
